* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 62.5%;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-container {
  cursor: pointer;
  overflow: hidden;
  transition: flex 0.3s ease-in-out;
}

.image-container:hover {
  flex: 2;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.enlarged {
  flex: 4 !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.swiper-slide_main {
  width: 100%;
}

.swiper-slide_main:nth-child(2n) {
  width: 80%;
}

.swiper-slide_main:nth-child(3n) {
  width: 60%;
}

.swiper_gallery {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  max-height: 40rem;
}

.swiper-slide_gallery {
  background-position: center;
  background-size: cover;
  width: 30rem;
  height: 30rem;
  max-height: 30rem;
}

.swiper-slide_gallery img,
.swiper-slide_main img {
  width: 100%;
  height: 100%;
}

.form {
  max-width: 800px;
  margin: 0 auto;
}

textarea,
select,
input {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 15px;
  font-size: 14px;
}

.label,
section > label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: white;
  font-size: 14px;
  font-weight: 200;
}

input[type='submit'],
.button {
  position: relative;
  background: #ec5990;
  color: white;
  text-transform: uppercase;
  border: none;
  font-weight: 600;
  margin-top: 20px;
  padding: 20px;
  font-size: 16px;
  letter-spacing: 2px;
  display: block;
  appearance: none;
  border-radius: 4px;
  width: 100%;
  font-weight: 400;
  letter-spacing: 0.5rem;
  transition: 0.3s all;
}

.error {
  color: red;
  font-size: 1.2rem; /* Adjust font size as needed */
  display: block;
  margin-top: 5px; /* Adjust vertical spacing as needed */
}

.rootTest {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
}
.avatarTest {
  margin: 10px;
  height: 80px;
  width: 80px;
  cursor: pointer;
}
.modalTest {
  display: flex;
  align-items: center;
  justify-content: center;
}
.paperTest {
  /* display: flex; */
  /* align-items: center; */
  background-color: white;
  /* flex-direction: column; */
  padding: '20px 40px 40px';
  max-width: 400px;
  max-height: 400px;
}
